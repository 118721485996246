import $ from "jquery";
import SetDevice from './SetDevice';
import Anchor from './Anchor';
import Header from './Header';
import PageTop from './PageTop';
import ScrollX from './ScrollX';
import KvSlider from './KvSlider';
import Accordion from './Accordion';
import NewsRelease from './NewsRelease';
import Numbers from './Numbers';
import InviewScroll from './InviewScroll';

$(() => {
  new SetDevice();
  // Anchor
  new Anchor();

  // Header
  if (document.getElementsByClassName("js-header").length) {
    new Header();
  }

  // PageTop
  if (document.getElementsByClassName("js-pagetop").length) {
    new PageTop();
  }

  // ScrollX
  if (document.getElementsByClassName("js-scroll-x").length) {
    new ScrollX();
  }

  // KvSlider
  if (document.getElementsByClassName("js-kv-slider").length) {
    new KvSlider();
  }

  // Accordion
  if (document.getElementsByClassName("js-accordion").length) {
    new Accordion();
  }

  // NewsRelease
  if (document.getElementsByClassName("js-newsRelease").length) {
    new NewsRelease();
  }

  // Numbers
  if (document.getElementsByClassName("js-numbers").length) {
    new Numbers();
  }

  // InviewScroll
  if (document.getElementsByClassName("js-inview").length) {
    new InviewScroll();
  }
});

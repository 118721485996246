import _ from "lodash";
import $ from "jquery";

export default class Accordion {
  constructor() {
    this.target = document.getElementsByClassName("js-accordion");
    this.lockFlg = false;
    this.init();
  }

  init() {
    if (!this.target.length) return false;

    this.bindEvents();

    // 初期表示
    Array.prototype.forEach.call(this.target, container => {
      if (container.classList.contains("-show")) {
        const content = container.getElementsByClassName("js-accordion-content")[0];

        if (content) {
          $(content).slideDown(0);
        }
      }
    });
  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
  bindEvents() {
    // Btn Click
    this.debouncedOnBtnClick = _.debounce((container, btn, content) => {
      if (container.classList.contains("-show")) {
        // hide
        container.classList.remove("-show");
        $(content).slideUp(500, () => {
          this.lockFlg = false;
        });
      } else {
        // show
        container.classList.add("-show");
        $(content).slideDown(500, () => {
          this.lockFlg = false;
        });
      }
    }, 10);
    Array.prototype.forEach.call(this.target, container => {
      const btn = container.getElementsByClassName("js-accordion-btn")[0];
      const content = container.getElementsByClassName("js-accordion-content")[0];

      if (btn && content) {
        btn.addEventListener("click", e => {
          e.preventDefault();
          e.stopPropagation();
          if (this.lockFlg) return false;
          this.lockFlg = true;
          this.debouncedOnBtnClick(container, btn, content);
        });
      }
    });
  }
}


import $ from "jquery";

export default class Anchor {
  constructor() {
    this.target = document.getElementsByClassName('js-anchor');
    this.speed = 500;
    this.mq = window.matchMedia("only screen and (max-width: 959px)").matches;
    this.init();
  }

  init() {
    // ページ読み込み時
    const hash = location.hash;
    const that = this;
    if (hash&&$(hash).length&&!(that.hash&&$('.js-newsRelease-btn[href="'+that.hash+'"]').length)) {
      $(window).on('load', function(){

        var pos = $(hash).offset().top - $('.js-header').height();
        if($(hash).data('pos')){
          pos = pos - $(hash).data('pos') + (that.mq?20:0);
        }
        window.location.hash = '';
        $('body,html').animate({ scrollTop: pos }, that.speed, 'swing');
      });
    }

    this.bindEvents();
  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
  bindEvents() {
    const that = this;
    // click event
    Array.prototype.forEach.call(this.target, elm => {
      elm.addEventListener("click", e => {
        const url = elm.attributes["href"].value;
        const href = url.replace(/#.*$/g, "");
        const path = window.location.pathname;
        const hash = url.split('/').slice(-1)[0].split('.html').slice(-1)[0];
        if (url==hash||href==path) {
          e.preventDefault();
          e.stopPropagation();
          var pos = $(hash).offset().top - $('.js-header').height();
          if($(hash).data('pos')){
            pos = pos - $(hash).data('pos') + (that.mq?20:0);
          }
          $('body,html').animate({ scrollTop: pos }, that.speed, 'swing');
        }
      });
    });
  }

}

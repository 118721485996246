import $ from "jquery";

export default class NewsRelease {
  constructor() {
    this.hash = location.hash;
    this.init();
  }

  init() {
    this.load_years();
    this.load_news();
  }
  load_years() {
    const that = this;
    if($('.js-load-years').length){
      $('.js-load-years').each(function(){
        $.getJSON('/common/js/news-release.json', function(data){
          for(var i in data){
            var h = '<a href="#news' + data[i].year + '"';
            h += 'class="newsRelease__year-btn ';
            if(i==0){
              h += '-current ';
            }
            h += 'js-newsRelease-btn">\n';
            h += '<p class="newsRelease__years">' + data[i].year + '</p>\n';
            h += '</a>\n';
            $('.js-load-years').append(h);
          }
        });
      });
    }
  }
  load_news() {
    const that = this;
    $('.js-load-news').each(function(){
      $.getJSON('/common/js/news-release.json', function(data){
        for(var i in data){
          var h = '<div id="news' + data[i].year + '"';
          h += ' class="newsRelease__contents__inner js-newsRelease-content';
          if(i==0){
            h += ' -show -visible">\n';
          }else{
            h += '">\n';
          }

          for(var j in data[i].news){
            h += '<a href="'+data[i].news[j].url+'" class="newsRelease__contents__link" target="_blank">\n';
            h += '<p class="newsRelease__contents__date">'+ data[i].year + '年' + data[i].news[j].date+'</p>\n';
            if(data[i].news[j].category == 'お知らせ'){
              h += '<p class="newsRelease__contents__tag--notice">\n';
            }else if(data[i].news[j].category == '人事'){
              h += '<p class="newsRelease__contents__tag--resource">';
            }else{
              h += '<p class="newsRelease__contents__tag">';
            }
            h += '<span>'+data[i].news[j].category+'</span>\n';
            h += '</p>\n';
            h += '<p class="newsRelease__contents__txt">'+data[i].news[j].title+'</p>\n';
            if(data[i].news[j].url.match( /\.pdf$/ )){
              h += '<p class="newsRelease__contents__pdf"><img src="/common/images/txt_pdf.svg" alt="PDF"></p>\n';
            }else{
              h += '<p class="newsRelease__contents__ex"><img src="/common/images/icon_link_ex.svg" alt=""></p>\n';
            }
            h += '</a>\n';
            if($('.js-load-news-top').length&&i==0&&j==2){
              break;
            }
          }
          h += '</div>\n';
          $('.js-load-news').append(h);
        }
        if ($('.js-newsRelease-btn').length) {
          that.load();
          that.open();
        }
      });

    });
  }
  load() {
    const that = this;
    if (that.hash&&$('.js-newsRelease-btn[href="'+that.hash+'"]').length) {
      $(window).on('load', function(){
        $('.js-newsRelease-btn').removeClass('-current');
        $('.js-newsRelease-btn[href="'+that.hash+'"]').addClass('-current');
        $('.js-newsRelease-content').removeClass('-show');
        $('.js-newsRelease-content').removeClass('-visible');
        $('.js-newsRelease-content'+that.hash).addClass('-show');
        $('.js-newsRelease-content'+that.hash).addClass('-visible');
        window.location.hash = '';
        $('html,body').scrollTop(0);
      });
    }
  }
  open() {
    $('.js-newsRelease').each(function(){
      const $target = $(this);
      const $trigger = $target.find('.js-newsRelease-btn');
      const contents = '.js-newsRelease-content';
      $trigger.on('click', function(e){
        e.preventDefault();
        const id = $(this).attr('href');
        const $content = $(contents + id);
        $trigger.removeClass('-current');
        $(this).addClass('-current');
        $target.find(contents).removeClass('-show');
        $content.addClass('-show');
        $target.find(contents).removeClass('-visible');
        setTimeout(() => {
          $content.addClass('-visible');
        }, 100);
        return false;
      });
    });
  }
}

import _ from "lodash";
import MobileDetect from "mobile-detect";
import IsPassiveSupport from "../lib/IsPassiveSupport";

export default class SetDevice {
  constructor() {
    this.ww = window.innerWidth;
    this.md = new MobileDetect(window.navigator.userAgent);
    this.ua = window.navigator.userAgent.toLowerCase();
    this.html = document.getElementsByTagName('html')[0];
    this.isMobile;
    this.isTablet;
    this.isPc;
    this.init();
  }

  init() {
    if (this.md) {
      this.isMobile = this.md.phone();
      this.isTablet = this.md.tablet();
    }
    this.isPc = (!this.isMobile && !this.isTablet) ? true : null;

    this.bindEvents();
    this.getDevice();
    this.setVariables();
  }

  /**
   * イベント設定
   * @return {undefined}
   */
  bindEvents() {
    const isPassive = new IsPassiveSupport();
    // Device Class
    if (this.isPc) {
      this.html.classList.add("is-pc");
    }else {
      this.html.classList.add("is-mb");
    }
    // Resize Event
    this.debouncedOnResize = _.debounce(() => {
      this.onResize();
    }, 600);
    window.addEventListener(
      "resize",
      e => {
        if (this.isPc) {
          this.debouncedOnResize();
        } else {
          // PC以外は横幅が変わっていたらリサイズ処理
          if (this.ww != window.innerWidth) {
            this.debouncedOnResize();
          }
        }
      },
      isPassive.check() ? { passive: true } : false
    );
  }

  /**
   * デバイス情報
   * @return {undefined}
   */
   getDevice() {
    if(this.isMobile){
      if (this.ua.indexOf('iphone') !== -1 || this.ua.indexOf('ipod') !== -1 ) {
        this.html.classList.add("is-ios");
      }
      if (this.ua.indexOf('android') !== -1) {
          this.html.classList.add("is-android");
      }
    }else if(this.isTablet){
      if (this.ua.indexOf('ipad') > 0) {
        this.html.classList.add("is-ios");
      }
      else if (this.ua.indexOf('android') > 0) {
        this.html.classList.add("is-android");
      }
    }else {
      if ((this.ua.indexOf('macintosh') > -1 && 'ontouchend' in document)) {
        this.html.classList.add("is-ios");
        this.html.classList.add("is-ipados");
      }
      if (this.ua.match(/win(dows )/)) {
        this.html.classList.add("is-windows-pc");
      }
      if (this.ua.indexOf('msie') != -1||this.ua.indexOf('trident') != -1) {
        this.html.classList.add("is-ie");
      }
      if(this.ua.indexOf("opera") > -1){
        this.html.classList.add("is-opera");
      }
      if(this.ua.indexOf("edge") > -1){
        this.html.classList.add("is-edge");
      }
      if (this.ua.indexOf('firefox') != -1) {
        this.html.classList.add("is-firefox");
      }
    }
    if (this.ua.indexOf('chrome') != -1) {
      this.html.classList.add("is-chrome");
    }else if (this.ua.indexOf('safari') != -1) {
      this.html.classList.add("is-safari");
    }
  }

  /**
   * html要素にcssカスタムプロパティ(--vh)追加
   * @return {undefined}
   */
   setVariables() {
    const vh = window.innerHeight * 0.01 + "px";
    document.documentElement.style.setProperty("--vh", vh);
  }
  /**
   * リサイズ後の処理
   * @return {undefined}
   */
  onResize() {
    this.setVariables();
    this.ww = window.innerWidth;
  }
}

import $ from "jquery";

export default class Anchor {
  constructor() {
    this.init();
  }

  init() {
    this.inViewAction = function(callback, namespace, elem, adjust) {
      if (typeof callback === 'function') {
        $(window).on('load scroll' + '.' + namespace, function(){
        var that = this,
          gpos = $(that).scrollTop(),
          elempos = elem.offset(),
          adjustVal = adjust ? adjust : 0;
        if(gpos >= (elempos.top - $(window).height() + adjustVal)){
          callback();
          $(that).off('.' + namespace)
        }
        });
      }
    }

    this.bindEvents();
  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
  bindEvents() {
    var that = this;
    $('.js-inview').each(function(index,elm) {
      var adj;
      var callBack = function(){
        $(elm).addClass('-show');
      }
      var name = 'fade_' + index;
      if($(elm).hasClass('-last')){
        adj = 100;
      }else{
        adj = $(window).height() * 0.25;
      }
      that.inViewAction(callBack, name, $(elm), adj);
    });
    if($('.js-inview-inner').length){
      $('.js-inview-inner').each(function(index,elm) {
        var adj;
        var callBack = function(){
          $(elm).addClass('-show--inner');
        }
        var name = 'fade_inner_' + index;
        if($(elm).hasClass('-last')){
          adj = 100;
        }else{
          adj = $(window).height() * 0.25;
        }
        that.inViewAction(callBack, name, $(elm), adj);
      });
    }
    if($('.js-inview-each').length){
      $('.js-inview-each').each(function(index,elm) {
        var adj;
        var callBack = function(){
          $(elm).find('.js-inview-each-item').each(function(i) {
            setTimeout(() => {
              $(this).addClass('-show--each');
            }, i*300);
          });
        }
        var name = 'fade_each_' + index;
        if($(elm).hasClass('-last')){
          adj = 100;
        }else{
          adj = $(window).height() * 0.25;
        }
        that.inViewAction(callBack, name, $(elm), adj);
      });
    }


  }


}

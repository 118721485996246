import $ from 'jquery';
import _ from 'lodash';

export default class Header {
  constructor() {
    this.target = document.getElementsByClassName('js-header');
    this.items = document.getElementsByClassName('js-header-menu-item');
    this.body = document.getElementsByTagName('body')[0];
    this.mq = window.matchMedia('only screen and (max-width: 959px)').matches;
    this.scrollY = window.pageYOffset;
    this.lockFlg = false;
    this.lockFlg_items = false;
    this.init();
  }

  init() {
    if (!this.target.length) return false;
    this.bindEvents();

  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
  bindEvents() {
    const that = this;
    var y;
    // Btn Click
    this.debouncedOnBtnClick = _.debounce((container, btn) => {
      if (container.classList.contains('-active')) {
        // hide
        container.classList.remove('-active');
        this.body.classList.remove('-fixed');
        this.body.style.top = '';
        document.documentElement.scrollTop = y;
        _.delay(function(){container.classList.remove('-visible')}, 500);
        this.lockFlg = false;
      } else {
        // show
        y = $(window).scrollTop();
        container.classList.add('-visible');
        container.classList.add('-active');
        this.lockFlg = false;
        _.delay(function(){
          that.body.classList.add('-fixed');
          that.body.style.top = y+'px';
        }, 500);
      }
    }, 10);
    Array.prototype.forEach.call(this.target, container => {
      const btn = container.getElementsByClassName('js-header-btn')[0];

      if (btn) {
        btn.addEventListener('click', e => {
          e.preventDefault();
          e.stopPropagation();
          if (this.lockFlg) return false;
          this.lockFlg = true;
          this.debouncedOnBtnClick(container, btn);
        });
      }
    });
    // Drawer
    this.debouncedOnMenuClick = _.debounce((items, btn, content) => {
      if($('html').hasClass('is-mb')){
        if (items.classList.contains('-show')) {
          // hide
          items.classList.remove('-show');
          $(content).slideUp(500, () => {
            this.lockFlg_items = false;
          });
        }else{
          // show
          items.classList.add('-show');
          if(!this.mq){
            $(document).on('touchstart click', document, function(event) {
              // hide
              if (!$(event.target).closest('.js-header-menu-drawer,.js-header-menu-drawer::before,.js-header-menu-item.-show').length) {
                //this.lockFlg_items = true;
                items.classList.remove('-show');
                $(content).slideUp(500);
                this.lockFlg_items = false;
              }
            });
          }

          $(content).slideDown(500, () => {
            this.lockFlg_items = false;
          });
        }
      }else{
      }
    }, 10);
    Array.prototype.forEach.call(this.items, items => {
      const btn = items.getElementsByClassName('js-header-menu-btn')[0];
      const content = items.getElementsByClassName('js-header-menu-drawer')[0];

      if (btn && content) {
        if($('html').hasClass('is-mb')){
          btn.addEventListener('click', e => {
            e.preventDefault();
            e.stopPropagation();
            if (this.lockFlg_items) return false;
            this.lockFlg_items = true;
            this.debouncedOnMenuClick(items, btn, content);
          });
        }else{
          btn.addEventListener('mouseenter', e => {
            e.preventDefault();
            e.stopPropagation();
            items.classList.add('-show');
            $(content).stop(true, false).slideDown(500);
          });
          items.addEventListener('mouseleave', e => {
            e.preventDefault();
            e.stopPropagation();
            items.classList.remove('-show');
            $(content).stop(true, false).slideUp(500);
          });
        }
      }
    });
    // Anchor
    $('.js-header-menu-anchor').on('click',function(){
      _.delay(() => {
        const url = $(this).attr('href');
        const href = url.replace(/#.*$/g, '');
        const path = window.location.pathname;
        const hash = url.split('/').slice(-1)[0].split('.html').slice(-1)[0];
        var pos = 0;
        if(href==path){
          $('.js-header-menu-item').removeClass('-show');
          $('.js-header').removeClass('-visible');
          $('body').removeClass('-fixed');
          $('body').attr('style','');
          if($(hash).data('pos')){
            pos = $(hash).offset().top - $('.header').height() - $(hash).data('pos') + (that.mq?20:0);
          }else{
            pos = $(hash).offset().top - $('.header').height();
          }
          $('.js-header-menu-drawer').slideUp(300, () => {
            this.lockFlg_items = false;
            $('.js-header').removeClass('-active');
          });
          $('body,html').animate({ scrollTop: pos },{
            duration: 500,
            easing: 'swing',
            queue: true
          });
        }
      }, 10);
    });
    this.debouncedOnResize = _.debounce(() => {

      this.onResize();
    }, 200);
    window.addEventListener(
      'resize',
      e => {
        if(!this.mq){
          $('.js-header').removeClass('-visible');
        }
        this.debouncedOnResize()
      }
    );

  }
  /**
   * リサイズ後の処理
   * @return {undefined}
   */
   onResize() {
    if(!this.mq){
      $('body').removeClass('-fixed');
      $('body').attr('style','');
      $('.js-header-menu-item').removeClass('-show');
      $('.js-header').removeClass('-active');
      $('.js-header-menu-drawer').attr('style','')
    }
  }
}


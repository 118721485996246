import $ from "jquery";
import PerfectScrollbar from 'perfect-scrollbar';

export default class ScrollX {
  constructor() {
    this.target = document.querySelector('.js-scroll-x');
    this.ps;
    this.ww = window.innerWidth ? window.innerWidth: $(window).width();
    this.cww = this.ww;
    this.init();
  }

  init() {
    this.ps = new PerfectScrollbar(this.target, {
      maxScrollbarLength: 546,
      minScrollbarLength: 20,
      wheelSpeed: 2
    });
  }
  bindEvents() {
    const that = this;
    $(window).on('load resize', function() {
      if (resizeTimer > 0) {
        clearTimeout(resizeTimer);
      }
      resizeTimer = setTimeout(function() {
        // ウインドウ幅が変わっていたら実行
        if (that.ww != that.cww ) {
          that.cww = that.ww;
          if(that.ps) {
            psUpdate(that.target)
          }
        }else if($('html').hasClass('is-pc')){
          if(that.ps) {
            psUpdate(that.target)
          }
        }
      }, 200);
    });

  }


}

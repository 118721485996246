import $ from "jquery";
import 'slick-carousel';

export default class KvSlider {
  constructor() {
    this.init();
  }

  init() {
    $(".js-kv-slider").slick({
      autoplay: true,
      autoplaySpeed: 4000,
      fade: true,
      speed: 2000,
      infinite: true,
      pauseOnFocus: false,
      pauseOnHover: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      arrows: false,
      dots: false
    });
    this.slider();
  }

  slider() {
    $(".js-kv-slider").find(".slick-slide").eq(0).addClass("-animation");
    $(".js-kv-slider").on({
      beforeChange: function(event, slick, currentSlide, nextSlide){
      navChange(nextSlide);
      $(".slick-slide", this).eq(currentSlide).addClass("-preve");
      $(".slick-slide", this).eq(nextSlide).addClass("-animation");
      },
      afterChange: function() {
        $(".-preve", this).removeClass("-preve -animation");
      }
    });
    function navChange(nextSlide) {
      const $nav = $(".js-top-bar");
      $nav.find('.kv__dots__bar').removeClass('-first');
      if(nextSlide>0){
        $nav.find('.kv__dots__bar').removeClass('-current');
        $nav.find('.kv__dots__bar:gt('+nextSlide+')').removeClass('-past');
        $nav.find('.kv__dots__bar:lt('+nextSlide+')').addClass('-past');
        $nav.find('.kv__dots__bar:eq('+nextSlide+')').addClass('-current');
      }else{
        $nav.find('.kv__dots__bar').removeClass('-current');
        $nav.find('.kv__dots__bar').removeClass('-past');
        $nav.find('.kv__dots__bar:eq('+nextSlide+')').addClass('-current');
      }
    }

  }

}

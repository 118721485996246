import $ from "jquery";

export default class Numbers {
  constructor() {
    this.target = $("js-numbers");
    this.ww = window.innerWidth;
    this.init();
  }

  init() {
    this.bindEvents();

  }

  /**
   * イベント処理設定
   * @return {undefined}
   */
   bindEvents() {
    $('.js-numbers-inview').each(function(index,elm) {
      var countElm = $(this).find('.js-numbers-count');
      var maxCount = parseFloat(countElm.data('count'));
      var count = 0;
      var fpsInterval, now, then, elapsed;
      var stopAnimationFlg = false;
      var callBack = function(){
        var fps = 60;
        fpsInterval = 1000 / fps;
        then = Date.now();
        animate();
      }
      var name = 'count_' + index;
      var adj = $(window).height() * 0.25;
      inView(callBack, name, $(elm), adj);
      function animate() {
        if (!stopAnimationFlg) {
          requestAnimationFrame(animate);
        }
        now = Date.now();
        elapsed = now - then;
        var endFlg = true;
        if (elapsed > fpsInterval) {
          then = now - (elapsed % fpsInterval);
          if(maxCount<100){
            count += Math.floor(maxCount / 10);
          }else{
            count += Math.floor(maxCount / 100);
          }
          if (count <= maxCount) {
            endFlg = false;
          } else {
            count = maxCount;
          }
          // 桁区切り
          var digit = countElm.data('digit');
          var countText;
          if(countElm.data('digit')){
            countText = '';
            var nums = String(count).replace(/(\d)(?=(\d\d\d\d)+$)/g, "$1,").split(",").reverse();
            for(var i=0;i<nums.length;i++){
              if(!nums[i].match(/^[0]+$/)){
                nums[i] = nums[i].replace(/^[0]+/g, "");
                if(nums[i].length == 4){
                  nums[i] = nums[i].replace( /(\d)(?=(\d\d\d)+)/g, '$1,' );
                }
                if(i==0){
                  countText = nums[i] + countText;
                }else{
                  countText = nums[i] + '<span class="numbers-content__unit">'+digit+'</span>' + countText;
                }
              }
            }
          }else{
            countText = String(count).replace( /(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
          }
          countElm.html(countText);
          if (endFlg) stopAnimationFlg = true;
        }
      }
      function inView(callback, namespace, elem, adjust) {
        if (typeof callback === 'function') {
          $(window).on('load scroll' + '.' + namespace, function(){
          var that = this,
            gpos = $(that).scrollTop(),
            elempos = elem.offset(),
            adjustVal = adjust ? adjust : 0;
          if(gpos >= (elempos.top - $(window).height() + adjustVal)){
            callback();
            $(that).off('.' + namespace)
          }
          });
        }
      }
    });
  }
}

